<script setup  lang="ts">
const {public: config} = useRuntimeConfig();
import { ref } from 'vue';

// Show/hide the modal dialog
const showModal = ref(false);
const dialgContent = ref({});
const openModal = (type= undefined) => {

  if (type === "term-conditions") {
    dialgContent.value= {
      title: "Term & Conditions",
      content: `
      Last Updated: June 06, 2024

<div class="text-xl font-bold">Introduction</div>
Welcome to ${config.appName}, operated by NinjaTopup. Throughout the site, the terms “we”, “us”, and “our” refer to ${config.appName}. This website, including all information, tools, and services available from it, is offered to you, the user, conditioned upon your acceptance of all terms, conditions, and notices stated here. By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions. Please read these Terms of Service carefully before accessing or using our website. If you have any questions about this policy, please contact us at ${config.appSupport}. We will respond to you promptly.

<div class="text-xl font-bold mt-4">Privacy Policy</div>
<div class="text-lg ">Personal Data We Collect</div>
We do not require you to register or use our Service through a third-party social network account (e.g., Facebook, Google), and you will not provide us or allow us to access your nickname, avatar, and other possible information related to such accounts. You can use the Service solely as a visitor, and you are not required to provide the information described in this paragraph.

<div class="text-lg mt-5">Account/Character Information</div>
To recharge your account/character, you need to provide specific information, such as your account number and character ID. The exact type of information required will be indicated on the corresponding webpage.

<div class="text-lg  mt-5">Contacting Us</div>
If you have any questions, complaints, or comments about this policy or our privacy practices, or to report any violations of this policy, please contact us at ${config.appSupport}.

<div class="text-lg   mt-5">Changes to This Privacy Policy</div>
We reserve the right to amend this policy from time to time to reflect any changes in the way we process your personal data or to comply with changing legal requirements without prior notice to you. Any amendments will be made available and published on ${config.appName} and will indicate the effective date. You can determine if any revisions have taken place by referring to the date this policy was last updated. We recommend that you check this policy periodically to review the latest version. Your continued use of our Service after an amendment constitutes your acknowledgment and acceptance of such changes. The most current version of this policy will govern our use of your information. Please check back frequently for any updates or changes. This policy was last effective on the date provided at the top of this page.

<div class="text-xl font-bold mt-4">Terms and Conditions</div>
By using the services provided by ${config.appName}, you agree to the following terms and conditions:

<div class="text-lg mt-5">Service Overview: </div>

${config.appName} offers game top-up services, allowing users to purchase in-game currency or items for various games.
Finality of Transactions: All transactions made on the website are final, and there is a strict no-refund policy in place.

<div class="text-lg  mt-5">User Responsibility: </div>

Users are responsible for ensuring that they select the correct game and server before making a purchase. In the event of any technical issues or bugs that result in multiple unintended purchases by a user, the user will be held responsible for any financial losses incurred by ${config.appName}.

<div class="text-lg  mt-5">Customer Support: </div>
Users are advised to contact customer support at ${config.appSupport} for any inquiries or assistance regarding their purchases.
Modifications to Terms: ${config.appName} reserves the right to modify these terms and conditions at any time without prior notice. Any changes will be posted on this page, and your continued use of the site constitutes acceptance of the new terms.`
    }
  }

  if (type === "refun-policy") {
    dialgContent.value= {
      title: "Refun Policy",
      content: `
      
        <p class="mb-5">At ${config.appName}, all sales are final. We do not offer refunds for any purchases made on our website. Once a transaction is completed, it cannot be reversed or refunded under any circumstances. We strongly encourage users to review their orders carefully before confirming payment to avoid any issues.</p>

        <div class="text-xl font-bold mb-4">Customer Support:</div>
        <p>For any customer support inquiries, please reach out to us at ${config.appSupport}. We're here to help!</p>
      `
    }
  }

  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};
</script>



<template>
  <footer p="y2 x2" flex="~ col gap4" class=" text-white text-sx py-6 mt-10" style="margin-top:200px">

    <div class="container mx-auto px-4">
      <!-- Footer Top Section -->
      <div class="flex flex-wrap items-center justify-between">
        <!-- Website Name -->
        <div class="w-full w-3 md:w-1/3 mb-1 md:mb-0 text-thin">
          <h1 class="text-2xl font-bold">{{config.appName}}</h1>
        </div>

       

        <!-- Powered By -->
        <div class="w-full w-3 md:w-1/3 flex md:justify-center mb-1 md:mb-0 gap-2">
          <p>Powered by </p>
          <a href="https://ninjatopup.com/" class="underline hover:no-underline">

            <svg width="auto" height="20" viewBox="0 0 875 147" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M168.503 120V56H208.503V64H216.503V120H200.503V72H184.503V120H168.503ZM224.503 120V64H240.503V120H224.503ZM224.503 56V40H240.503V56H224.503ZM248.503 120V56H288.503V64H296.503V120H280.503V72H264.503V120H248.503ZM304.503 136V120H312.503V80H304.503V64H328.503V128H320.503V136H304.503ZM312.503 56V40H328.503V56H312.503ZM368.503 104V96H352.503V104H368.503ZM344.503 120V112H336.503V88H344.503V80H368.503V72H336.503V56H376.503V64H384.503V120H344.503ZM400.503 120V112H392.503V40H408.503V56H424.503V72H408.503V104H424.503V120H400.503ZM464.503 104V72H448.503V104H464.503ZM440.503 120V112H432.503V64H440.503V56H472.503V64H480.503V112H472.503V120H440.503ZM520.503 104V72H504.503V104H520.503ZM488.503 136V56H528.503V64H536.503V112H528.503V120H504.503V136H488.503ZM552.503 120V112H544.503V56H560.503V104H576.503V56H592.503V120H552.503ZM632.503 104V72H616.503V104H632.503ZM600.503 136V56H640.503V64H648.503V112H640.503V120H616.503V136H600.503ZM656.503 120V104H672.503V120H656.503ZM688.503 120V112H680.503V64H688.503V56H720.503V64H728.503V80H712.503V72H696.503V104H712.503V96H728.503V112H720.503V120H688.503ZM768.503 104V72H752.503V104H768.503ZM744.503 120V112H736.503V64H744.503V56H776.503V64H784.503V112H776.503V120H744.503ZM792.503 120V56H824.503V64H832.503V56H848.503V64H856.503V120H840.503V72H832.503V120H816.503V72H808.503V120H792.503Z" fill="white"/>
              <rect x="312.645" y="38.5732" width="16.2413" height="16.2413" fill="#BB0101"/>
              <path d="M41.1108 15.2264H78.1612V18.2716H88.8196V21.8244H93.3875V24.8697H98.4629V28.4225H105.061V21.8244H108.614V18.2716H115.212V15.2264H119.78V12.1812H125.87V24.8697H122.317V31.9752H119.78V35.528H115.212V39.0808H119.78V42.6336H127.9V45.6788H132.468V48.7241H127.9V52.7844H115.212V55.8296H116.734V60.905H118.764V81.2067H117.749V93.3876H115.212V100.493H112.166V106.584H108.614V110.136H105.061V113.689H102.016V117.242H98.9704L98.4629 120.287H95.4176V123.84H91.3573V127.393H85.2668V130.438H74.6085V133.991H55.3219V132.468H47.7088V130.438H37.0505V127.393H29.4374V123.84H23.3469V120.287H20.3016V116.735H17.2564V113.689H13.7036V110.136H10.1508V103.538H6.59803V96.4329H3.04524V93.3876V86.2821H0V58.8749H3.04524V48.7241H6.59803V41.6185H10.1508V38.0657H13.7036V32.9903H17.2564V28.4225H24.3619V24.8697H27.9147V21.8244H34.5128V18.2716H41.1108V15.2264Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M105.061 69.5332H17.2562V72.5784H13.7034V76.1312H10.6582V86.7896H13.7034V89.8348H17.2562V93.3876H102.016V89.8348H105.061V85.7745H108.614V72.5784H105.061V69.5332ZM30.4524 76.1313V73.086H37.0505V76.1313H40.0957V82.7293H37.0505V86.2821H30.4524V82.7293H27.4072V76.1313H30.4524ZM78.1613 73.086V86.2821H88.3121V73.086H78.1613Z" fill="#190303"/>
            </svg>
          </a>
        </div>

         <!-- Terms & Conditions and Refund Policy -->
        <div class="w-full w-3 md:w-1/3 md:text-right text-xs flex md:justify-end items-center mb-1">
          <nav class="space-x-2 text-xs">
            <button @click="openModal('term-conditions')" class="hover:underline text-xs">Terms & Conditions</button> <button>|</button><button @click="openModal('refun-policy')" class="hover:underline text-xs">Refund Policy</button>
          </nav>
        </div>
      </div>
    </div>

    <!-- Modal (Dialog) -->
    <transition name="fade">
      <div v-if="showModal" class="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
        <div class="bg-gray-800 p-6 rounded-md max-w-md w-full max-h-[80vh] overflow-auto">
          <h2 class="text-xl font-bold mb-4">{{dialgContent.title}}</h2>
          <div class="modal-content">
              <div v-html="dialgContent.content">
              </div>
              <button @click="closeModal" class="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md">Close</button>
            </div>
          </div>
      </div>
    </transition>

    <!-- <div flex="~ row gap2" items-center my2>
      <img src="/movies-sm.webp" width="25" height="25" alt="Logo">
      <div text-xl>
        Nuxt Movies
      </div>
    </div> -->

    <!-- <div flex="~ row gap2" items-center>
      <p text-true-gray:90 text-sm>
        Powered by&nbsp;
        <a op100 target="_blank" href="https://www.appxstudio.io" title="Appxstudio" rel="noopener" n-link-text>
          <IconAxs inline  width="120"   />
        </a>
      </p>
    </div>

    <div flex gap4 items-center>
      <LanguageSwitcher />
    </div> -->
  </footer>
</template>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
</style>
